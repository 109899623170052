import type { AppProps } from 'next/app'
import Head from 'next/head'

import { SupabaseContextProvider } from '../lib/supabase'
import { AuthContextProvider } from '../lib/auth'
import '../styles/globals.css'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <SupabaseContextProvider>
      <AuthContextProvider>
        <Head>
          <title>{process.env.NEXT_PUBLIC_APP_NAME || 'Connections'}</title>
          <meta
            name="description"
            content={process.env.NEXT_PUBLIC_APP_DESCRIPTION || 'Build considerate communities across the globe 🌏.'}
          />
          <link rel="icon" href="/images/diamond.svg" />
          <meta property="og:title" content={process.env.NEXT_PUBLIC_APP_NAME || 'Connections'} />
          <meta
            property="og:description"
            content={process.env.NEXT_PUBLIC_APP_DESCRIPTION || 'Build considerate communities across the globe 🌏.'}
          />
          <meta property="og:image" content="/images/open-graph-image.png" />
          <meta property="og:url" content={process.env.NEXT_PUBLIC_APP_BASE_URL} />
          <meta property="og:type" content="website" />
        </Head>
        <Component {...pageProps} />
      </AuthContextProvider>
    </SupabaseContextProvider>
  )
}
export default MyApp
