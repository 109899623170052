import { Session } from '@supabase/gotrue-js'
import { NextApiRequest } from 'next'

import { SUPABASE_AUTH_COOKIE_KEY } from '../constants'
import { getCookie } from '../cookie'
import {
  AccessRow,
  AvatarRow,
  LocationRow,
  MemberCoordinate,
  ProfileRow,
  User,
  UserAvatar,
  UserLocation,
  UserLocationsRow,
  UserProfile,
} from '../types'

import { supabaseClient } from './supabase'

// Retrieve the profile of the currently logged in user. Profiles may not exist for newly registered users.
export const fetchProfile = async (req: NextApiRequest): Promise<UserProfile | null> => {
  const { user } = await setAuth(req)

  const { data, error } = await supabaseClient
    .from<ProfileRow & { avatar: AvatarRow | null }>('profiles')
    .select(
      `
      full_name,
      preferred_name,
      about,
      facebook_username,
      linkedin_username,
      avatar:avatar_id (
        id,
        mime_type,
        size,
        data
      )
    `,
    )
    .eq('user_id', user.id)
    .maybeSingle()

  if (error) throw error
  if (data?.avatar && data.avatar.mime_type !== 'image/jpeg') {
    throw new Error(`Unsupported MIME type for Avatar: ${data.avatar.id}`)
  }

  return data
    ? {
        fullName: data.full_name,
        preferredName: data.preferred_name,
        about: data.about,
        facebookUsername: data.facebook_username,
        linkedinUsername: data.linkedin_username,
        avatar: data.avatar
          ? {
              id: data.avatar.id,
              mimeType: data.avatar.mime_type as 'image/jpeg',
              size: data.avatar.size,
              data: data.avatar.data,
            }
          : null,
      }
    : null
}

// TODO: Make this insertion an RPC call and avoid passing the user, preferring instead the auth.uid() function.
// Set the profile of the currently logged in user.
export const upsertProfile = async (user: User, profile: Omit<ProfileRow, 'user_id'>) => {
  const { data, error } = await supabaseClient
    .from<ProfileRow>('profiles')
    .upsert(
      {
        user_id: user.id,
        ...profile,
      },
      { returning: 'minimal' },
    )
    .single()

  if (error) throw error

  return data
}

// Fetch the location of the currently logged-in user.
export const fetchLocation = async (req: NextApiRequest): Promise<UserLocation | null> => {
  await setAuth(req)

  const { data, error } = await supabaseClient
    .rpc<{ id: number; name: string; state: string; coordinates: string }>('own_location')
    .single()

  if (error) throw error
  if (data === null) return data

  let location = JSON.parse(data?.coordinates)

  return {
    id: data.id,
    name: data.name,
    state: data.state,
    point: { longitude: location.coordinates[0], latitude: location.coordinates[1] },
  }
}

// TODO: Make this insertion an RPC call and avoid passing the user, preferring instead the auth.uid() function.
// Add a location to the database and associate it with the currently logged-in user.
export const upsertLocation = async (user: User, location: UserLocation) => {
  const { data: locationData, error: locationError } = await supabaseClient
    .from<LocationRow>('locations')
    .upsert(
      {
        name: location.name,
        state: location.state,
        coordinates: `POINT(${location.point.longitude} ${location.point.latitude})`,
        metadata: JSON.stringify(location.metadata),
      },
      { onConflict: 'coordinates' },
    )
    .single()

  if (locationError) throw locationError

  const { error: userLocationsError } = await supabaseClient
    .from<UserLocationsRow>('user_locations')
    .upsert(
      {
        user_id: user.id,
        location_id: locationData?.id,
      },
      { returning: 'minimal', onConflict: 'user_id' },
    )
    .single()

  if (userLocationsError) throw userLocationsError

  return { success: true }
}

// Fetch the coordinates and count of members at all locations.
export const fetchMemberLocations = async (req: NextApiRequest): Promise<Array<MemberCoordinate> | null> => {
  await setAuth(req)

  const { data, error } = await supabaseClient.rpc<{
    id: number
    name: string
    coordinates: string
    has_admin: boolean
    user_count: number
  }>('member_locations')

  if (error) throw error
  if (data === null) return data

  return data.map((row) => {
    let location = JSON.parse(row.coordinates)
    return {
      id: row.id,
      name: row.name,
      point: { longitude: location.coordinates[0], latitude: location.coordinates[1] },
      hasAdmin: row.has_admin,
      userCount: row.user_count,
    }
  })
}

// Fetch the list of members registered at a single coordinate point.
export const fetchMembersAtLocation = async (locationId: number) => {
  const { data, error } = await supabaseClient.rpc<ProfileRow & { avatar: AvatarRow | null; admin: boolean }>(
    'members_at_location',
    {
      _location_id: locationId,
    },
  )

  if (error) throw error
  if (data === null) return data

  return data.map((row) => {
    return {
      userId: row.user_id,
      fullName: row.full_name,
      preferredName: row.preferred_name,
      about: row.about,
      facebookUsername: row.facebook_username,
      linkedinUsername: row.linkedin_username,
      admin: row.admin,
      avatar: row.avatar
        ? {
            id: row.avatar.id,
            mimeType: row.avatar.mime_type as 'image/jpeg',
            size: row.avatar.size,
            data: row.avatar.data,
          }
        : null,
    }
  })
}

// Fetch the list of members registered in a single state.
export const fetchMembersFromState = async (state: string) => {
  const { data, error } = await supabaseClient.rpc<
    ProfileRow & { avatar: AvatarRow | null; admin: boolean; location_name: string }
  >('members_from_state', {
    _state: state,
  })

  if (error) throw error
  if (data === null) return data

  return data.map((row) => {
    return {
      userId: row.user_id,
      fullName: row.full_name,
      preferredName: row.preferred_name,
      about: row.about,
      facebookUsername: row.facebook_username,
      linkedinUsername: row.linkedin_username,
      admin: row.admin,
      locationName: row.location_name,
      avatar: row.avatar
        ? {
            id: row.avatar.id,
            mimeType: row.avatar.mime_type as 'image/jpeg',
            size: row.avatar.size,
            data: row.avatar.data,
          }
        : null,
    }
  })
}

// Retrieve the avatar of the currently logged in user.
export const fetchAvatar = async (user: User): Promise<UserAvatar | null> => {
  const { data, error } = await supabaseClient
    .from<AvatarRow>('avatars')
    .select('id, mime_type, size, data')
    .eq('user_id', user.id)
    .maybeSingle()

  if (error) throw error
  if (data && data.mime_type !== 'image/jpeg') throw new Error(`Unsupported MIME type for Avatar: ${data.id}`)

  return data ? { mimeType: data.mime_type as 'image/jpeg', ...data } : null
}

// Upload a base64 encoded image as a user's Avatar.
export const upsertAvatar = async (req: NextApiRequest, avatar: Omit<AvatarRow, 'id' | 'user_id'>) => {
  const { user } = await setAuth(req)

  const { data, error } = await supabaseClient
    .from<AvatarRow>('avatars')
    .upsert(
      {
        user_id: user.id,
        ...avatar,
      },
      { returning: 'minimal', onConflict: 'user_id' },
    )
    .single()

  if (error) throw error

  return data
}

// Check the access of the current user.
export const checkAccess = async (req?: NextApiRequest, session?: Session): Promise<boolean> => {
  let user
  if (req) {
    const { user: userFromAuth } = await setAuth(req)
    user = userFromAuth
  }
  if (!req && session) {
    // Set access token for request
    supabaseClient.auth.setAuth(session.access_token)
    user = session.user
  }

  if (!user) throw new Error('Missing user on checkAccess request')

  const { data, error } = await supabaseClient
    .from<AccessRow>('user_access')
    .select('user_id')
    .eq('user_id', user.id)
    .maybeSingle()

  if (error) throw error
  return data ? true : false
}

// Insert a users validated access code
export const insertAccessCode = async (req: NextApiRequest, accessCode: string, admin: boolean) => {
  const { user } = await setAuth(req)

  const { data, error } = await supabaseClient
    .from<AccessRow>('user_access')
    .insert(
      {
        user_id: user.id,
        access_code: accessCode,
        admin,
      },
      { returning: 'minimal' },
    )
    .single()

  if (error) throw error

  return data
}

// Sets the currently logged in user for requests from the supabase client.
//
// WARNING: Each request in the NextJS Node environment should call this function because there isn't
// a guarantee that the supabase client state is shared due to NextJS running Node in a
// serverless environment.
// TODO: Move into a middleware that can be called on all calls inside /pages/api...
const setAuth = async (req: NextApiRequest) => {
  const supabaseJWT = getCookie(req, SUPABASE_AUTH_COOKIE_KEY)
  if (supabaseJWT) supabaseClient.auth.setAuth(supabaseJWT)
  const { user, error } = await supabaseClient.auth.api.getUserByCookie(req)

  if (error) throw error
  if (user === null) throw new Error(`No user data found on Supabase JWT ${SUPABASE_AUTH_COOKIE_KEY}`)

  return { user }
}
