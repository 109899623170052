import React, { createContext, useEffect, useState } from 'react'
import { AuthChangeEvent, Session, User } from '@supabase/supabase-js'
import { useSupabase } from '../supabase'
import { useRouter } from 'next/router'
import { ROUTE_LOGIN, ROUTE_ROOT } from '../constants'

export type AuthContextType = {
  session: Session | null
  user: User | null
} | null

export const AuthContext = createContext<AuthContextType>(null)

export const AuthContextProvider = (props: React.PropsWithChildren<{}>) => {
  const supabase = useSupabase()
  const router = useRouter()
  const [session, setSession] = useState<Session | null>(supabase.auth.session())
  const [user, setUser] = useState<User | null>(supabase.auth.user())

  useEffect(() => {
    const session = supabase.auth.session()
    setSession(session)
    setUser(session?.user ?? null)

    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, session) => {
      // Update the context values for `user` and `session`
      setSession(session)
      setUser(session?.user ?? null)

      await setSessionCookie(event, session)

      // Event-specific logic
      switch (event) {
        case 'PASSWORD_RECOVERY':
          break
        case 'SIGNED_IN':
          if (session) {
            const [access, _] = await Promise.all([checkAccess(session), uploadAvatar(session)])
            if (access) router.push(ROUTE_ROOT)
          }
          break
        case 'SIGNED_OUT':
          setSession(null)
          setUser(null)
          router.push(ROUTE_LOGIN)
          break
        case 'USER_DELETED':
          break
        case 'USER_UPDATED':
          break
      }
    })

    return () => {
      authListener?.unsubscribe()
    }
  }, [supabase.auth, router])

  return <AuthContext.Provider value={{ session, user }} {...props} />
}

const checkAccess = async (session: Session | null): Promise<boolean> => {
  debugger
  const result = await fetch(new URL('/api/access/check', process.env.NEXT_PUBLIC_APP_BASE_URL).toString(), {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin',
    body: JSON.stringify({ session }),
  })

  return result.ok
}

// Post the session to /api/auth/session in order to update the browser cookie for the session.
// NOTE: this is only needed if you're doing SSR (getServerSideProps)!
// Source: https://github.com/supabase/supabase/blob/master/examples/nextjs-with-supabase-auth/pages/index.js
const setSessionCookie = async (event: AuthChangeEvent, session: Session | null) => {
  await fetch(new URL('/api/auth/session', process.env.NEXT_PUBLIC_APP_BASE_URL).toString(), {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json' }),
    credentials: 'same-origin',
    body: JSON.stringify({ event, session }),
  })
}

const uploadAvatar = async (session: Session | null) => {
  if (!session || !session.user) return

  const avatarUrl: string = session.user.user_metadata?.avatar_url
  if (!avatarUrl) return

  await fetch(new URL('/api/avatars/upload', process.env.NEXT_PUBLIC_APP_BASE_URL).toString(), {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json', 'Cookie': `sb:token=${session.access_token}` }),
    credentials: 'same-origin',
    body: JSON.stringify({ avatarUrl }),
  })
}
