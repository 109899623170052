import { useContext } from 'react'
import { SupabaseClient } from '@supabase/supabase-js'

import { SupabaseContext } from './context'

export const useSupabase = (): SupabaseClient => {
  const supabase = useContext(SupabaseContext)
  if (supabase === undefined || supabase === null) {
    throw new Error('useSupabase must be used within a SupabaseContextProvider.')
  }

  return supabase
}
