import { useContext } from 'react'

import { AuthContext, AuthContextType } from './context'

export const useAuth = (): NonNullable<AuthContextType> => {
  const context = useContext(AuthContext)
  if (context === undefined || context === null) {
    throw new Error('useAuth must be used within a AuthContextProvider.')
  }

  return context
}
